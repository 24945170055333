$(function() {
  var $body           = $('body'),
      isTouchDevice   = ('ontouchstart' in window),
      fixHeaderShowed = false;

  if (isTouchDevice) {
    $(window)
      .on('scroll', function() {
        var cScroll = $(this).scrollTop();

        if (cScroll > 1 && fixHeaderShowed === false) {
          fixHeaderShowed = true;

          $body.addClass('css-fixed-header');
        } else if (cScroll <= 1) {
          fixHeaderShowed = false;

          $body.removeClass('css-fixed-header');
        }
      })
      .trigger('scroll');
  }
});